import React, { useEffect, useRef, useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import getRandomString from "../util/getRandomString";
import CssTextField from "../components/CssTextField";
import ColorButton from "../components/ColorButton";
import {
  Alert,
  Backdrop,
  Badge,
  Box,
  CircularProgress,
  Container,
  Popper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Logo from "../components/Logo";
import AppBar from "../components/AppBar";
import signInIntoApp from "../util/signInIntoApp";
import { getAuth } from "firebase/auth";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  APP_ID,
  AUTH_KEY,
  REGION,
  WEB_APP_DYNAMIC_LINK,
  WEB_APP_HOME,
  WIDGET_ID,
} from "../config";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stack from "@mui/material/Stack";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import { CometChatCalls } from "@cometchat-pro/web-calls";

function Call() {
  const { id: sessionID } = useParams();
  const navigate = useNavigate();
  const isMeetingURL = /[a-z]{4,4}-[a-z]{4,4}-[a-z]{4,4}/.test(
    sessionID ? sessionID : ""
  );
  const [meetCode, setMeetCode] = useState(isMeetingURL ? sessionID : "");
  const [noOfParticipants, setNoOfParticipants] = useState(-1);
  const [callStatus, setCallStatus] = useState(false);
  const [inputError, setInputError] = useState("");
  const [user, setUser] = React.useState<CometChat.User | null>(null);
  const [isMeetingInfoOpen, setIsMeetingInfoOpen] = React.useState(false);
  const [isChatOpen, setIsChatOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isVideoOn, setIsVideoOn] = React.useState(true);
  const [isMicOn, setIsMicOn] = React.useState(true);
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  const videoPreviewRef = useRef<HTMLVideoElement | null>(null);
  const mediaSteamRef = useRef<MediaStream | null>(null);
  const callTokenRef = useRef("");
  const [presentationMode, setPresentationMode] = useState("false");
  const [isPresenter, setIsPresenter] = useState("false");

  useEffect(() => {
    if (!user) return;
    return;
    window.addEventListener("message", (evt) => {
      if (user?.getUid()) {
        CometChat.sendMessage(
          new CometChat.TextMessage(
            user?.getUid(),
            evt.data,
            CometChat.RECEIVER_TYPE.USER
          )
        ).then(
          (message) => {
            console.log("Message sent successfully:", message);
          },
          (error) => {
            console.log("Message sending failed with error:", error);
          }
        );
      }
    });
  }, [user]);

  useEffect(() => {
    if (isChatOpen) {
      setUnreadMessageCount(0);
    }
  }, [isChatOpen]);

  useEffect(() => {
    if (callStatus || !isVideoOn) {
      const mediaSteam = mediaSteamRef.current;
      mediaSteam?.getTracks().forEach(function (track: any) {
        if (track.readyState === "live") {
          track.stop();
        }
      });
      mediaSteamRef.current = null;
      return;
    }
    if (!videoPreviewRef.current) return;
    const videoPreview = videoPreviewRef.current;
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then((stream) => {
        mediaSteamRef.current = stream;
        videoPreview.srcObject = stream;
        videoPreview.addEventListener("loadedmetadata", () => {
          videoPreview.play();
        });
      })
      .catch((reason: DOMException) => {
        console.log(reason.code);
      });
  }, [callStatus, isVideoOn]);

  useEffect(() => {
    if (inputError) {
      if (!meetCode) {
        setInputError("Please put meet code or url");
        return;
      }
      const parsedSessionId = meetCode.replace(WEB_APP_HOME, "");
      if (!/^[a-z]{4,4}-[a-z]{4,4}-[a-z]{4,4}$/.test(parsedSessionId)) {
        setInputError("Invalid meet code or url");
        return;
      }
      setInputError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetCode]);

  useEffect(() => {
    setInputError("");
  }, [user]);

  useEffect(() => {
    CometChat.getLoggedinUser().then(
      (user) => {
        setUser(user);
        if (!user) {
          // navigate("/");
        } else if (isMeetingURL) {
          const callType = "DIRECT";

          const getParticpantsJoined = () => {
            CometChat.getCallParticipantCount(
              sessionID as string,
              callType
            ).then(
              (count) => {
                console.log("Participants count =", count);
                setNoOfParticipants(count);
                setTimeout(getParticpantsJoined, 6000);
              },
              (error) => {
                console.log("Some error occurred =", error);
              }
            );
          };

          getParticpantsJoined();
        }
      },
      (error) => {
        console.log("Something went wrong", error);
      }
    );
  }, [navigate, sessionID, isMeetingURL]);

  const handleJoinCall = () => {
    if (!validateInput()) {
      return;
    }
    if (!user) {
      signInIntoApp().then((user) => {
        setUser(user as CometChat.User | null);
        CometChat.getUnreadMessageCountForGroup(meetCode!)
          .then((res: any) => {
            setUnreadMessageCount(res?.[meetCode!] || 0);
            setInputError("");
            joinCall();
          })
          .catch((err) => {
            if (err.code === "ERR_GUID_NOT_FOUND") {
              setInputError("Invalid meet code or url");
            } else {
              joinCall();
            }
          });
      });
    } else {
      CometChat.getUnreadMessageCountForGroup(meetCode!)
        .then((res: any) => {
          setUnreadMessageCount(res?.[meetCode!] || 0);
          setInputError("");
          joinCall();
        })
        .catch((err) => {
          if (err.code === "ERR_GUID_NOT_FOUND") {
            setInputError("Invalid meet code or url");
          } else {
            joinCall();
          }
        });
    }
  };

  const validateInput = () => {
    if (!meetCode) {
      setInputError("Please put meet code or url");
      return false;
    }
    const parsedSessionId = meetCode.replace(WEB_APP_HOME, "");
    if (!/[a-z]{4,4}-[a-z]{4,4}-[a-z]{4,4}/.test(parsedSessionId)) {
      setInputError("Invalid meet code or url");
      return false;
    }
    setInputError("");
    return true;
  };

  const generateToken = async () => {
    if (!meetCode) {
      return;
    }
    let loggedInUser = await CometChat.getLoggedinUser();
    if (loggedInUser !== null) {
      let authToken = loggedInUser.getAuthToken();
      console.log(9999999999, authToken);
      // let sessionID = "testreact";
      if (!callTokenRef.current) {
        CometChatCalls.generateToken(meetCode, `${authToken}`).then(
          (res) => {
            console.log("Call token fetched: ", res.token);
            callTokenRef.current = res.token;
          },
          (err) => {
            console.log("Generating call token failed with error: ", err);
          }
        );
      }
    }
  };
  useEffect(() => {
    generateToken();
  }, [meetCode]);

  const joinCall = () => {
    const listener = new CometChatCalls.OngoingCallListener({
      // onUserJoined: (user: CometChat.User) => {
      //   console.log("user joined:", user);
      // },
      // onUserLeft: (user: CometChat.User) => {
      //   console.log("user left:", user);
      // },
      onUserListUpdated: (userList) => {
        console.log("user list:", userList);
        if (userList.length === 1) {
          setIsMeetingInfoOpen(true);
        }
      },
      onCallEnded: () => {
        setCallStatus(false);
      },
      onError: (error: CometChat.CometChatException) => {
        console.log("Error :", error);
      },
      onMediaDeviceListUpdated: (deviceList: Object) => {
        console.log("Device List:", deviceList);
      },
      // onUserMuted: (
      //   userMuted: CometChat.User,
      //   userMutedBy: CometChat.User
      // ) => {
      //   // This event will work in JS SDK v3.0.2-beta1 & later.
      //   console.log("Listener => onUserMuted:", userMuted, userMutedBy);
      // },
      onScreenShareStarted: () => {
        // This event will work in JS SDK v3.0.3 & later.
        console.log("Screen sharing started.");
      },
      onScreenShareStopped: () => {
        // This event will work in JS SDK v3.0.3 & later.
        console.log("Screen sharing stopped.");
      },
    });
    if (presentationMode === "true") {
      const callSettings = new CometChatCalls.PresenterSettingsBuilder()
        .startWithVideoMuted(!isVideoOn)
        .enableDefaultLayout(true)
        .startWithAudioMuted(!isMicOn)
        // .setSessionID(meetCode!)
        .setIsPresenter(isPresenter === "true")
        .setIsAudioOnlyCall(false)
        .setCallListener(listener)
        .build();
  
      CometChatCalls.joinPresentation(
        callTokenRef.current,
        callSettings,
        document.getElementById("callScreen") as HTMLElement
      );
      setCallStatus(true);
    } else {
      const callSettings = new CometChatCalls.CallSettingsBuilder()
        .startWithVideoMuted(!isVideoOn)
        .enableDefaultLayout(true)
        .startWithAudioMuted(!isMicOn)
        // .setSessionID(meetCode!)
        .setIsAudioOnlyCall(false)
        .setCallListener(listener)
        .build();
  
      CometChatCalls.startSession(
        callTokenRef.current,
        callSettings,
        document.getElementById("callScreen") as HTMLElement
      );
      setCallStatus(true);
    }
    // initializeChatWidget(meetCode!);
  };

  const initializeChatWidget = (groupId: string) => {
    (window as any).CometChatWidget.init({
      appID: APP_ID,
      appRegion: REGION,
      authKey: AUTH_KEY,
    }).then(
      (_response: any) => {
        const chatElement = document.getElementById("chat-widget");
        if (chatElement) {
          chatElement.innerHTML = "";
        }
        (window as any).CometChatWidget.launch({
          widgetID: WIDGET_ID,
          target: "#chat-widget",
          roundedCorners: "false",
          height: "var(--chat-box-height)",
          width: "min(100vw, 400px)",
          defaultID: groupId,
          defaultType: "group",
        });
        (window as any).CometChatWidget.on(
          "onMessageReceived",
          (message: string) => {
            setUnreadMessageCount((prev) => prev + 1);
          }
        );
      },
      (error: any) => {
        console.log("Initialization failed with error:", error);
        CometChat.endCall(meetCode!);
      }
    );
  };

  return (
    <React.Fragment>
      <AppBar visible={!callStatus} user={user} setUser={setUser} />
      <Backdrop
        sx={{
          color: "var(--bgcolor)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        id="callScreen"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          overflow: "hidden",
          left: -1,
          display: callStatus ? "" : "none",
          // zIndex: 999999,
        }}
      >
        <div
          id="chat-widget"
          style={{
            transform: `translate(${
              isChatOpen ? "0px" : "min(100vw, 400px)"
            }, 0px)`,
          }}
        ></div>
        <div
          className="chat-icon"
          style={{
            right: isChatOpen
              ? "calc(min(100vw, 400px) - 15px)"
              : "var(--chat-icon-distance)",
            backgroundColor: isChatOpen ? "var(--bgcolor)" : "transparent",
          }}
        >
          <IconButton
            onClick={() => setIsChatOpen((prev) => !prev)}
            sx={{ padding: 0 }}
          >
            {isChatOpen ? (
              <ArrowForwardIosIcon style={{ color: "#333" }} />
            ) : (
              <Badge badgeContent={unreadMessageCount} color="primary">
                <ChatOutlinedIcon style={{ color: "#ffffffa0" }} />
              </Badge>
            )}
          </IconButton>
        </div>
        <Popper open={callStatus} style={{ zIndex: 999999, top: 10, left: 10 }}>
          {isMeetingInfoOpen ? (
            <Box
              sx={{
                p: 2,
                bgcolor: "background.paper",
                borderRadius: 2,
                maxWidth: "min(350px, 100vw - 52px)",
              }}
            >
              {/* <span >X</span> */}
              <IconButton
                style={{ position: "absolute", right: 10, top: 10 }}
                size="small"
                onClick={() => setIsMeetingInfoOpen(false)}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="body1"
                style={{ fontSize: 20, marginBottom: 10 }}
              >
                {" "}
                Your meeting info{" "}
              </Typography>
              <Typography
                variant="body1"
                className="text-type1"
                style={{ marginBottom: 10 }}
              >
                {" "}
                Share this joining info with others who you want to join the
                meetings{" "}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{ fontSize: 14, color: "#333" }}
                >
                  {" "}
                  cometchat.pages.dev/{meetCode}{" "}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => {
                    if (meetCode) {
                      navigator.clipboard.writeText(
                        WEB_APP_DYNAMIC_LINK.replace("__ID__", meetCode)
                      );
                    }
                  }}
                >
                  <ContentCopyRoundedIcon />
                </IconButton>
              </div>
              {getAuth().currentUser?.email && (
                <Typography
                  variant="body1"
                  style={{ fontSize: 12, color: "#555", marginTop: 10 }}
                >
                  {" "}
                  Joined as {getAuth().currentUser?.email}{" "}
                </Typography>
              )}
            </Box>
          ) : (
            <IconButton
              onClick={() => setIsMeetingInfoOpen(true)}
              style={{ display: isChatOpen ? "none" : "" }}
            >
              <InfoOutlinedIcon style={{ color: "var(--bgcolor)" }} />
            </IconButton>
          )}
        </Popper>
      </div>
      <div className="cc" style={{ display: callStatus ? "none" : "" }}>
        <Container maxWidth="sm">
          <Logo
            style={{
              flexGrow: 1,
              alignSelf: "center",
              margin: "20px 0px",
              padding: 20,
            }}
            svgStyle={{ height: "auto", width: "100%" }}
            fill="var(--primary-text)"
          />
          <div className="video-container">
            <video className="video-preview" ref={videoPreviewRef} />
            {!isVideoOn && (
              <Typography
                variant="h4"
                style={{
                  position: "absolute",
                  color: "var(--bgcolor)",
                  alignSelf: "center",
                }}
              >
                Camera is off
              </Typography>
            )}
            <Stack
              direction="row"
              spacing={3}
              style={{ position: "absolute", bottom: 35 }}
            >
              <IconButton
                style={{
                  border: `2px solid ${
                    isMicOn ? "var(--bgcolor)" : "var(--primary)"
                  }`,
                  backgroundColor: isMicOn ? "transparent" : "var(--primary)",
                }}
                size="large"
                onClick={() => setIsMicOn((prev) => !prev)}
              >
                {isMicOn ? (
                  <MicNoneOutlinedIcon className="toogle-icon" />
                ) : (
                  <MicOffOutlinedIcon className="toogle-icon" />
                )}
              </IconButton>
              <IconButton
                style={{
                  border: `2px solid ${
                    isVideoOn ? "var(--bgcolor)" : "var(--primary)"
                  }`,
                  backgroundColor: isVideoOn ? "transparent" : "var(--primary)",
                }}
                size="large"
                onClick={() => setIsVideoOn((prev) => !prev)}
              >
                {isVideoOn ? (
                  <VideocamOutlinedIcon className="toogle-icon" />
                ) : (
                  <VideocamOffOutlinedIcon className="toogle-icon" />
                )}
              </IconButton>
            </Stack>
          </div>
          <div style={{display: "flex", justifyContent: "space-around"}}>
            <ToggleButtonGroup
              color="primary"
              value={presentationMode}
              exclusive
              onChange={(e, f) => {
                if (f) setPresentationMode(f)
              }}
            >
              <ToggleButton value="false">Normal Call</ToggleButton>
              <ToggleButton value="true">Presentation Call</ToggleButton>
            </ToggleButtonGroup>
            {presentationMode === "true" && <ToggleButtonGroup
              color="primary"
              value={isPresenter}
              exclusive
              onChange={(e, f) => {
                if (f) setIsPresenter(f)
              }}
            >
              <ToggleButton value="true">Presenter</ToggleButton>
              <ToggleButton value="false">Participant</ToggleButton>
            </ToggleButtonGroup>}
          </div>

          <div style={{height: 20}}></div>

          {noOfParticipants === 0 && (
            <h1 style={{ color: "var(--primary-text)" }}>
              No one else is here.
            </h1>
          )}
          {noOfParticipants > 0 && (
            <h1 style={{ color: "var(--primary-text)" }}>
              {noOfParticipants} already in call.
            </h1>
          )}
          <CssTextField
            value={meetCode}
            onChange={(e) => setMeetCode(e.target.value)}
            disabled={isMeetingURL}
            label="Enter code or URL"
            style={{ width: "100%" }}
          />
          {inputError && (
            <Alert style={{ marginTop: 20 }} severity="error" variant="filled">
              {inputError}
            </Alert>
          )}
          <div className="btn-container">
            {isMeetingURL && (
              <ColorButton
                variant="contained"
                style={{ flex: 1, marginTop: 20 }}
                onClick={() => {
                  if (sessionID) {
                    navigator.clipboard
                      .writeText(
                        WEB_APP_DYNAMIC_LINK.replace("__ID__", sessionID)
                      )
                      .then(
                        function () {
                          alert("Invitation link copied to clipboard.");
                        },
                        function () {
                          alert("Eroro");
                        }
                      );
                  }
                }}
              >
                Copy Invite Link
              </ColorButton>
            )}
            {!isMeetingURL && (
              <ColorButton
                variant="contained"
                style={{ flex: 1, marginTop: 20 }}
                onClick={() => {
                  if (!user) {
                    setInputError("Please login first.");
                    return;
                  }
                  const newMeetId = `${getRandomString()}-${getRandomString()}-${getRandomString()}`;

                  const GUID: string = newMeetId!;
                  const groupName: string = `Meeting Chat - ${newMeetId}`;
                  const groupType: string = CometChat.GROUP_TYPE.PUBLIC;

                  const group: CometChat.Group = new CometChat.Group(
                    GUID,
                    groupName,
                    groupType
                  );
                  group.setIcon(
                    "https://cdn-icons-png.flaticon.com/512/32/32441.png"
                  );

                  setLoading(true);
                  CometChat.createGroup(group).then(
                    (_group: CometChat.Group) => {
                      setLoading(false);
                      setMeetCode(newMeetId);
                      navigate(`/${newMeetId}`, { state: { id: newMeetId } });
                    },
                    (error: CometChat.CometChatException) => {
                      setLoading(false);
                      alert("Group creation failed with exception:");
                      console.log(error);
                    }
                  );
                }}
              >
                Start Instant Meeting
              </ColorButton>
            )}
            <ColorButton
              variant="contained"
              onClick={handleJoinCall}
              style={{ flex: 1, marginTop: 20 }}
            >
              {user ? "" : "Login & "}Join
            </ColorButton>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Call;
